import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, ActivityIndicator, Keyboard, TouchableWithoutFeedback, StyleSheet, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import * as SecureStore from 'expo-secure-store'; // For mobile secure storage
import { API_URL } from '../services/api';

export default function LoginScreen() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigation = useNavigation();

  // Get device type
  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "PC"; // Sau "Unknown" pentru alte cazuri
  };

  // Get public IP address
  const getPublicIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching public IP:', error);
      return null;
    }
  };

  // Load remembered data and Remember Me state
  useEffect(() => {
    const loadRememberMeState = async () => {
      try {
        const rememberMeOption = await AsyncStorage.getItem('rememberMe');
        if (rememberMeOption === 'true') {
          setRememberMe(true);

          const rememberedUsername = await AsyncStorage.getItem('username');
          let rememberedPassword;

          if (Platform.OS !== 'web') {
            rememberedPassword = await SecureStore.getItemAsync('password');
          } else {
            rememberedPassword = await AsyncStorage.getItem('password');
          }

          // Only auto-login if both username and password are retrieved
          if (rememberedUsername && rememberedPassword) {
            setUsername(rememberedUsername);
            setPassword(rememberedPassword);
            await handleLogin(rememberedUsername, rememberedPassword);
          }
        }
      } catch (error) {
        console.error('Error loading remembered data:', error);
      }
    };

    loadRememberMeState();
  }, []);

  // Handle Remember Me checkbox change
  const handleRememberMeChange = async () => {
    const newRememberMeState = !rememberMe;
    setRememberMe(newRememberMeState);
    await AsyncStorage.setItem('rememberMe', newRememberMeState ? 'true' : 'false');
  };

  // Handle login submission
  const handleLogin = async (user = username, pass = password) => {
    if (!user.trim() || !pass.trim()) {
      Alert.alert('Error', 'Username and password are required');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_URL}/login`, {
        username: user.trim(),
        password: pass.trim(),
        device: getDeviceType(),
        ip: await getPublicIP(),
      });

      if (response.data.success) {
        const { token, user_id } = response.data;

        // Save token and user_id in AsyncStorage
        await AsyncStorage.setItem('token', token);
        await AsyncStorage.setItem('user_id', user_id.toString());

        // Save username and password if Remember Me is checked
        if (rememberMe) {
          await AsyncStorage.setItem('username', user);
          if (Platform.OS !== 'web') {
            await SecureStore.setItemAsync('password', pass);
          } else {
            await AsyncStorage.setItem('password', pass);
          }
        }

        // Navigate to the home screen after successful login
        navigation.replace('HomeTabs');
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred during login. Please try again.');
      } else if (error.request) {
        setError('No response from the server. Please check your network.');
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <TouchableWithoutFeedback onPress={Platform.OS !== 'web' ? Keyboard.dismiss : undefined}>
      <View style={styles.container}>
        <Text style={styles.header}>Welcome Back</Text>

        <TextInput
          style={styles.input}
          placeholder="Username"
          placeholderTextColor="#A5A5A5"
          value={username}
          onChangeText={setUsername}
          autoCapitalize="none"
        />

        <TextInput
          style={styles.input}
          placeholder="Password"
          placeholderTextColor="#A5A5A5"
          secureTextEntry={!showPassword}
          value={password}
          onChangeText={setPassword}
        />

        <View style={styles.optionsContainer}>
          <TouchableOpacity onPress={handleRememberMeChange}>
            <Text style={styles.rememberMeText}>
              {rememberMe ? '☑' : '☐'} Remember Me
            </Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
            <Text style={styles.showPasswordText}>
              {showPassword ? 'Hide Password' : 'Show Password'}
            </Text>
          </TouchableOpacity>
        </View>

        {error ? <Text style={styles.errorText}>{error}</Text> : null}

        <TouchableOpacity style={styles.loginButton} onPress={() => handleLogin()}>
          {loading ? <ActivityIndicator color="#fff" /> : <Text style={styles.loginButtonText}>Log in</Text>}
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigation.navigate('RecoverPassword')}>
          <Text style={styles.forgotPasswordText}>Forgot Password?</Text>
        </TouchableOpacity>

      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#364649',
  },
  header: {
    color: '#fff',
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    width: '80%',
    height: 50,
    backgroundColor: '#708F96',
    borderRadius: 10,
    color: '#E0D8CC',
    paddingLeft: 15,
    marginBottom: 20,
  },
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginBottom: 20,
  },
  rememberMeText: {
    color: '#E0D8CC',
  },
  showPasswordText: {
    color: '#E0D8CC',
  },
  loginButton: {
    width: '80%',
    height: 50,
    backgroundColor: '#AA895F',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 20,
  },
  loginButtonText: {
    color: '#364649',
    fontSize: 18,
  },
  errorText: {
    color: 'red',
    marginBottom: 20,
  },
  forgotPasswordText: {
    color: '#E0D8CC',
    marginTop: 20,
  },
});
