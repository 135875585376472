import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity, Alert, StyleSheet, ActivityIndicator, Platform } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../services/api';

export default function IpScreen() {
  const [ipList, setIpList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all IPs from the server (with duplicates marked)
  const fetchIps = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.get(`${API_URL}/ips`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIpList(response.data.ips); // Ensure you're setting the correct data structure here
      setLoading(false);
    } catch (error) {
      console.error('Error fetching IPs:', error);
      setError('Failed to fetch IPs. Please try again later.');
      setLoading(false);
    }
  };

  // Function to delete a single IP
  const deleteIp = async (ip, ruleNumber) => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.post(`${API_URL}/ips/unblock`, { ip, ruleNumber }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.data.success) {
        Alert.alert('Success', 'IP unblocked and deleted successfully');
        fetchIps(); // Refresh the list after deletion
      } else {
        Alert.alert('Error', 'Failed to delete IP');
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Error response:', error.response);
      } else if (error.request) {
        // Request was made but no response received
        console.error('Error request:', error.request);
      } else {
        // Something else happened while setting up the request
        console.error('Error:', error.message);
      }
      Alert.alert('Error', `Failed to delete IP: ${error.message}`);
    }
  };

  // Save all IPs from iptables to the database
  const saveAllIPs = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.post(`${API_URL}/ips/save`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        if (Platform.OS === 'web') {
          window.alert('Success: IPs saved successfully');
        } else {
          Alert.alert('Success', 'IPs saved successfully');
        }
        fetchIps(); // Refresh the list after saving IPs
      }
    } catch (error) {
      console.error('Error saving IPs:', error);
      if (Platform.OS === 'web') {
        window.alert('Error: Failed to save IPs');
      } else {
        Alert.alert('Error', 'Failed to save IPs');
      }
    }
  };

  // Delete all IPs from the database (without unblocking)
  const deleteAllIPs = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.delete(`${API_URL}/ips/deleteAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        if (Platform.OS === 'web') {
          window.alert('Success: All IPs deleted from the database');
        } else {
          Alert.alert('Success', 'All IPs deleted from the database');
        }
        fetchIps(); // Refresh the list after deletion
      }
    } catch (error) {
      console.error('Error deleting all IPs:', error);
      if (Platform.OS === 'web') {
        window.alert('Error: Failed to delete all IPs');
      } else {
        Alert.alert('Error', 'Failed to delete all IPs');
      }
    }
  };

  useEffect(() => {
    fetchIps();
  }, []);

  return (
    <View style={styles.container}>
      {error && <Text style={styles.errorText}>{error}</Text>}
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        {loading ? (
          <ActivityIndicator size="large" color="#AA895F" />
        ) : (
          <View style={styles.ipList}>
            {ipList.map((ipEntry, index) => (
              <View
                key={index}
                style={[
                  styles.ipItem,
                  ipEntry.is_duplicate > 1 && styles.duplicateIP, // Highlight duplicates
                ]}
              >
                <Text style={styles.ipText}>IP: {ipEntry.ip_address}</Text>
                <Text style={styles.ipText}>Rule Number: {ipEntry.rule_number}</Text>
                <Text style={styles.ipText}>Packets: {ipEntry.packets}</Text>
                <Text style={styles.ipText}>Bytes: {ipEntry.bytes}</Text>
                <Text style={styles.ipText}>Created At: {new Date(ipEntry.created_at).toLocaleString()}</Text>
                {ipEntry.is_duplicate > 1 && (
                  <Text style={styles.duplicateText}>Duplicate IP</Text> // Mark duplicates
                )}
                <TouchableOpacity
                  style={styles.deleteButton}
                  onPress={() => deleteIp(ipEntry.ip_address, ipEntry.rule_number)} // Pass both ip and rule number
                >
                  <Text style={styles.deleteButtonText}>Delete IP</Text>
                </TouchableOpacity>
              </View>
            ))}
          </View>
        )}
      </ScrollView>
      <TouchableOpacity onPress={saveAllIPs} style={styles.actionButton}>
        <Text style={styles.actionButtonText}>Save All IPs</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={deleteAllIPs} style={styles.actionButton}>
        <Text style={styles.actionButtonText}>Delete All IPs</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    backgroundColor: '#364649',
    padding: 20,
  },
  ipItem: {
    backgroundColor: '#708F96',
    padding: 15,
    marginBottom: 15,
    borderRadius: 10,
  },
  duplicateIP: {
    backgroundColor: '#BB2233', // Highlight color for duplicates
  },
  ipText: {
    color: '#E0D8CC',
    fontSize: 16,
  },
  deleteButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#AA895F',
    borderRadius: 5,
  },
  deleteButtonText: {
    color: '#364649',
    textAlign: 'center',
    fontSize: 16,
  },
  ipList: {
    flex: 1,
  },
  actionButton: {
    backgroundColor: '#AA895F',
    padding: 15,
    marginTop: 15,
    borderRadius: 10,
    alignItems: 'center',
  },
  actionButtonText: {
    color: '#364649',
    fontSize: 16,
  },
  duplicateText: {
    color: 'red',
    fontWeight: 'bold',
  },
  errorText: {
    color: 'red',
    marginBottom: 20,
  },
});
