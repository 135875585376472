import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, Alert, StyleSheet, Platform, Keyboard, TouchableWithoutFeedback } from 'react-native';
import axios from 'axios';
import * as ImagePicker from 'expo-image-picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL, IMAGE_URL } from '../services/api';

export default function UpdateContactDetailsScreen({ navigation }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const fileInputRef = useRef(null); // Ref for the file input in web

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userId = await AsyncStorage.getItem('user_id');
      if (userId) {
        try {
          const response = await axios.get(`${API_URL}/user/${userId}`);
          const user = response.data.user;
          setUsername(user.username);
          setEmail(user.email);
          setAvatar(user.avatar ? { uri: `${IMAGE_URL}/avatar/${user.avatar}` } : null);
        } catch (error) {
          Alert.alert('Error', 'Failed to load user details');
        }
      }
    };

    fetchUserDetails();
  }, []);

  const handleChooseAvatar = async () => {
    if (Platform.OS === 'web') {
      // For web, trigger the file input click
      fileInputRef.current.click();
    } else {
      // For mobile, use the ImagePicker
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
      if (!result.canceled) {
        setAvatar({
          uri: result.assets[0].uri,
          type: result.assets[0].type || 'image/jpeg',
          name: `avatar_${Date.now()}.jpg`,
        });
      }
    }
  };
  
  // This handles file input changes on the web
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const localUri = URL.createObjectURL(file);
      setAvatar({
        uri: localUri,
        file: file,
        name: file.name,
        type: file.type,
      });
    }
  };
  

  const handleUpdateContactDetails = async () => {
    if (!username || !email) {
      if (Platform.OS === 'web') {
        window.alert('Error: Username and email are required.');
      } else {
        Alert.alert('Error', 'Username and email are required');
      }
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('email', email);
  
      if (avatar && avatar.uri && !avatar.uri.startsWith('http')) {
        // On web, append the file directly
        if (Platform.OS === 'web') {
          formData.append('avatar', avatar.file);
        } else {
          // On mobile, use uri, type, and name for the avatar
          formData.append('avatar', {
            uri: avatar.uri,
            type: avatar.type,
            name: avatar.name,
          });
        }
      }
  
      const userId = await AsyncStorage.getItem('user_id');
      const response = await axios.put(`${API_URL}/user/${userId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
  
      if (response.data.success) {
        if (Platform.OS === 'web') {
          window.alert('Success: Contact details updated.');
        } else {
          Alert.alert('Success', 'Contact details updated');
        }
        navigation.goBack(); // Navigate back after the update
      } else {
        if (Platform.OS === 'web') {
          window.alert('Error: Failed to update details.');
        } else {
          Alert.alert('Error', 'Failed to update details');
        }
      }
    } catch (error) {
      console.error('Error updating contact details:', error);
      if (Platform.OS === 'web') {
        window.alert('Error: Failed to update details.');
      } else {
        Alert.alert('Error', 'Failed to update details');
      }
    }
  };
  
  const Container = Platform.OS === 'web' ? View : TouchableWithoutFeedback;
  return (
    <Container onPress={Platform.OS === 'web' ? null : Keyboard.dismiss}>
    <View style={styles.container}>
      <Text style={styles.title}>Update Contact Details</Text>
  
      <TextInput
        style={styles.input}
        placeholder="Username"
        value={username}
        onChangeText={setUsername}
        placeholderTextColor="#A5A5A5"
        autoCapitalize="none"
      />
      <TextInput
        style={styles.input}
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        keyboardType="email-address"
        placeholderTextColor="#A5A5A5"
        autoCapitalize="none"
      />
  
      {avatar && <Image source={{ uri: avatar.uri }} style={styles.avatar} />}
  
      {Platform.OS === 'web' && (
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileInputChange}
        />
      )}
  
      <TouchableOpacity onPress={handleChooseAvatar} style={styles.avatarButton}>
        <Text style={styles.avatarButtonText}>Change Avatar</Text>
      </TouchableOpacity>
  
      <TouchableOpacity onPress={handleUpdateContactDetails} style={styles.updateButton}>
        <Text style={styles.updateButtonText}>Update</Text>
      </TouchableOpacity>
    </View>
    </Container>
  );
  }
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#364649', // Fundal actualizat
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
    },
    title: {
      color: '#E0D8CC', // Culoare de text actualizată
      fontSize: 24,
      marginBottom: 20,
      textAlign: 'center',
    },
    input: {
      backgroundColor: '#708F96', // Fundal pentru input actualizat
      color: '#E0D8CC', // Culoarea textului în input
      marginBottom: 20,
      padding: 10,
      borderRadius: 10,
      width: '100%',
    },
    avatarButton: {
      backgroundColor: '#AA895F', // Culoare buton avatar actualizată
      padding: 15,
      borderRadius: 10,
      width: '100%',
      marginBottom: 20,
    },
    avatarButtonText: {
      color: '#364649', // Culoare text buton avatar actualizată
      textAlign: 'center',
      fontSize: 16,
    },
    avatar: {
      width: 100,
      height: 100,
      borderRadius: 50,
      marginBottom: 20,
    },
    updateButton: {
      backgroundColor: '#AA895F', // Culoare buton actualizată
      padding: 15,
      borderRadius: 10,
      width: '100%',
    },
    updateButtonText: {
      color: '#364649', // Culoare text buton actualizată
      textAlign: 'center',
      fontSize: 16,
    },
  });
  
