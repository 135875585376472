import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Base URLs for API and image assets
export const API_URL = 'https://srv571349.hstgr.cloud:8445/api';
export const IMAGE_URL = 'https://srv571349.hstgr.cloud:8445/assets';

// Axios interceptor to set Authorization header
axios.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('No token found, cannot set Authorization header'); // Debugging log
    }
    return config;
  },
  (error) => {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
);