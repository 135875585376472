import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity, Alert, StyleSheet, TextInput, ActivityIndicator, Keyboard, TouchableWithoutFeedback, Platform } from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { API_URL } from '../services/api';

export default function HomeScreen() {
  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredBackups, setFilteredBackups] = useState([]);
  const [days, setDays] = useState('');
  const [currentRetentionDays, setCurrentRetentionDays] = useState(null);
  const [updatingRetentionDays, setUpdatingRetentionDays] = useState(false);
  const [error, setError] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState({});

  // Group database backups by directory
  const groupDatabaseBackupsByDirectory = (backups) => {
    const grouped = {};
    backups.forEach((backup) => {
      if (backup.backup_type === 'database') {
        const key = backup.filepath;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(backup);
      }
    });
    return Object.values(grouped);
  };

  // Fetch all backups
  const fetchBackups = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.get(`${API_URL}/backups/sorted`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const groupedDatabaseBackups = groupDatabaseBackupsByDirectory(response.data.backups);
      const allBackups = [
        ...response.data.backups.filter((b) => b.backup_type === 'files'),
        ...groupedDatabaseBackups,
      ];
      setBackups(allBackups);
      setFilteredBackups(allBackups);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching backups:', error);
      setError('Failed to fetch backups. Please try again later.');
      setLoading(false);
    }
  };

  // Fetch retention days from the server
  const fetchRetentionDays = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.get(`${API_URL}/backups/retention-days`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCurrentRetentionDays(response.data.days);
    } catch (error) {
      console.error('Error fetching retention days:', error);
      setError('Failed to fetch retention days.');
    }
  };

  useEffect(() => {
    fetchBackups();
    fetchRetentionDays();
  }, []);

  // Update retention days function for both mobile and web
  const updateRetentionDays = async () => {
    const daysInt = parseInt(days, 10);
    if (!daysInt || isNaN(daysInt)) {
      if (Platform.OS === 'web') {
        window.alert('Error: Please enter a valid number of days.');
      } else {
        Alert.alert('Error', 'Please enter a valid number of days.');
      }
      return;
    }
    setUpdatingRetentionDays(true);
    try {
      const token = await AsyncStorage.getItem('token');
      if (!token) {
        if (Platform.OS === 'web') {
          window.alert('Error: Authentication token is missing.');
        } else {
          Alert.alert('Error', 'Authentication token is missing.');
        }
        return;
      }
      const response = await axios.post(
        `${API_URL}/backups/update-retention-days`,
        { days: daysInt },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.success) {
        if (Platform.OS === 'web') {
          window.alert(`Success: Retention days updated to ${daysInt}`);
        } else {
          Alert.alert('Success', `Retention days updated to ${daysInt}`);
        }
        setCurrentRetentionDays(daysInt);
      } else {
        if (Platform.OS === 'web') {
          window.alert('Error: Failed to update retention days');
        } else {
          Alert.alert('Error', 'Failed to update retention days');
        }
      }
    } catch (error) {
      console.error('Error updating retention days:', error.response || error);
      if (Platform.OS === 'web') {
        window.alert('Error: Failed to update retention days');
      } else {
        Alert.alert('Error', 'Failed to update retention days');
      }
    }
    setUpdatingRetentionDays(false);
  };

  // Function to delete a backup (for both mobile and web)
  const confirmDelete = (id, filepath, backupType, isGroup) => {
    if (Platform.OS === 'web') {
      const confirmDelete = window.confirm(`Are you sure you want to delete this ${isGroup ? 'group' : 'file'}?`);
      if (confirmDelete) {
        deleteBackup(id, filepath, isGroup);
      }
    } else {
      Alert.alert(
        'Confirm Delete',
        `Are you sure you want to delete this ${isGroup ? 'group' : 'file'}?`,
        [
          { text: 'Cancel', style: 'cancel' },
          { text: 'Delete', onPress: () => deleteBackup(id, filepath, isGroup) },
        ]
      );
    }
  };

  // Function to delete backup from server
  const deleteBackup = async (id, filepath, isGroup) => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.delete(`${API_URL}/backups/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { filepath, isGroup },
      });

      if (response.data.success) {
        if (Platform.OS === 'web') {
          window.alert('Success: Backup deleted successfully');
        } else {
          Alert.alert('Success', 'Backup deleted successfully');
        }
        fetchBackups();
      } else {
        if (Platform.OS === 'web') {
          window.alert('Error: Failed to delete backup');
        } else {
          Alert.alert('Error', 'Failed to delete backup');
        }
      }
    } catch (error) {
      console.error('Error deleting backup:', error.response || error);
      if (Platform.OS === 'web') {
        window.alert('Error: Failed to delete backup');
      } else {
        Alert.alert('Error', 'Failed to delete backup');
      }
    }
  };

  // Create instant backup
  const createInstantBackup = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/backups/instant`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        if (Platform.OS === 'web') {
          window.alert('Success: Instant backup created successfully');
        } else {
          Alert.alert('Success', 'Instant backup created successfully');
        }
        fetchBackups();
      } else {
        if (Platform.OS === 'web') {
          window.alert('Error: Failed to create instant backup');
        } else {
          Alert.alert('Error', 'Failed to create instant backup');
        }
      }
    } catch (error) {
      console.error('Error creating instant backup:', error.response || error);
      if (Platform.OS === 'web') {
        window.alert('Error: Failed to create instant backup');
      } else {
        setError('Failed to create an instant backup. Please try again.');
      }
    }
  };

  // Toggle backup group visibility
  const toggleGroup = (key) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Render backup item
  const renderBackupItem = (item) => {
    if (!Array.isArray(item)) {
      const formattedDate = new Date(item.backup_date).toISOString().split('T')[0];
      return (
        <View style={styles.backupItem}>
          <Text style={styles.backupText}>Filename: {item.filename}</Text>
          <Text style={styles.backupText}>Type: {item.backup_type}</Text>
          <Text style={styles.backupText}>Date: {formattedDate}</Text>
          <TouchableOpacity
            style={styles.deleteButton}
            onPress={() => confirmDelete(item.id, item.filepath, item.backup_type, false)}
          >
            <Text style={styles.deleteButtonText}>Delete File</Text>
          </TouchableOpacity>
        </View>
      );
    }

    if (item.length > 0) {
      const formattedDate = new Date(item[0].backup_date).toISOString().split('T')[0];
      const filenames = item.map((b) => b.filename).join(', ');
      const isExpanded = expandedGroups[item[0].filepath];

      return (
        <View style={styles.backupItem}>
          <TouchableOpacity onPress={() => toggleGroup(item[0].filepath)}>
            <Text style={styles.backupText}>Filenames: {filenames}</Text>
            <Text style={styles.backupText}>Type: database</Text>
            <Text style={styles.backupText}>Directory: {item[0].filepath}</Text>
            <Text style={styles.backupText}>Date: {formattedDate}</Text>
          </TouchableOpacity>

          {isExpanded && (
            <View>
              {item.map((backup, index) => (
                <Text key={index} style={styles.backupText}>
                  Backup: {backup.filename}
                </Text>
              ))}
            </View>
          )}

          <TouchableOpacity
            style={styles.deleteButton}
            onPress={() => confirmDelete(item[0].id, item[0].filepath, item[0].backup_type, true)}
          >
            <Text style={styles.deleteButtonText}>Delete Group</Text>
          </TouchableOpacity>
        </View>
      );
    }

    return null;
  };

  return (
    
      <View style={styles.container}>
        {error && <Text style={styles.errorText}>{error}</Text>}

        <View style={styles.actionsContainer}>
          <TouchableOpacity onPress={createInstantBackup} style={styles.button}>
            <Text style={styles.buttonText}>Create Instant Backup</Text>
          </TouchableOpacity>

          <View style={styles.daysContainer}>
            <TextInput
              placeholder="Days"
              style={styles.input}
              value={days}
              onChangeText={setDays}
              keyboardType="numeric"
            />
            <TouchableOpacity onPress={updateRetentionDays} style={styles.button} disabled={updatingRetentionDays}>
              <Text style={styles.buttonText}>Update Days</Text>
            </TouchableOpacity>
          </View>
          {currentRetentionDays && (
            <Text style={styles.infoText}>Current delete days: {currentRetentionDays}</Text>
          )}
        </View>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
        {loading ? (
          <ActivityIndicator size="large" color="#AA895F" />
        ) : (
          <View style={styles.backupList}>
            {filteredBackups.map((item, index) => (
              <View key={index}>{renderBackupItem(item)}</View>
            ))}
          </View>
        )}
        </ScrollView>
      </View>
    
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    backgroundColor: '#364649',
    padding: 20,
  },
  backupItem: {
    backgroundColor: '#708F96',
    padding: 15,
    marginBottom: 15,
    borderRadius: 10,
  },
  backupText: {
    color: '#E0D8CC',
    fontSize: 16,
  },
  deleteButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#AA895F',
    borderRadius: 5,
  },
  deleteButtonText: {
    color: '#364649',
    textAlign: 'center',
    fontSize: 16,
  },
  actionsContainer: {
    marginVertical: 20,
  },
  daysContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  input: {
    backgroundColor: '#E0D8CC',
    color: '#364649',
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    marginRight: 10,
    flex: 1,
  },
  button: {
    backgroundColor: '#AA895F',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    alignItems: 'center',
  },
  buttonText: {
    color: '#364649',
    fontSize: 16,
  },
  infoText: {
    color: '#E0D8CC',
    marginTop: 10,
    fontSize: 16,
  },
  backupList: {
    flex: 1,
  },
  errorText: {
    color: 'red',
    marginBottom: 20,
  },
});
