import React from 'react';  
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons } from '@expo/vector-icons';
import LoginScreen from './src/screens/LoginScreen';
import SignupScreen from './src/screens/SignupScreen';
import HomeScreen from './src/screens/HomeScreen';
import IpScreen from './src/screens/IpScreen';
import SettingsScreen from './src/screens/SettingsScreen';
import ChangePasswordScreen from './src/screens/ChangePasswordScreen';
import AccountDetailsScreen from './src/screens/AccountDetailsScreen';  // Import the AccountDetailsScreen
import UpdateContactDetailsScreen from './src/screens/UpdateContactDetailsScreen';  // Import the UpdateContactDetailsScreen
import RecoverPasswordScreen from './src/screens/RecoverPasswordScreen';  // Import the RecoverPasswordScreen
import { Text } from 'react-native'; 

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

// Custom theme
const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#364649',  // Global background color
  },
};

function HomeTabs() {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          if (route.name === 'Backup List') {
            iconName = focused ? 'card' : 'card-outline';
          } else if (route.name === 'IPs List') {
            iconName = focused ? 'settings' : 'settings-outline';
          }else if (route.name === 'Settings') {
            iconName = focused ? 'settings' : 'settings-outline';
          }
          return <Ionicons name={iconName} size={size} color={color} />;
        },
        tabBarLabel: ({ focused }) => (
          <Text style={{ color: focused ? '#AA895F' : '#E0D8CC' }}>{route.name}</Text>
        ),  // Wrap label in Text component
        tabBarActiveTintColor: '#AA895F',
        tabBarInactiveTintColor: '#E0D8CC',
        tabBarStyle: { backgroundColor: '#364649' },
        headerTitleAlign: 'center',
        headerStyle: { backgroundColor: '#364649' },
        headerTintColor: '#E0D8CC',
        headerTitleStyle: { fontSize: 24 },
      })}
    >
      <Tab.Screen name="Backup List" component={HomeScreen} />
      <Tab.Screen name="IPs List" component={IpScreen} />
      <Tab.Screen name="Settings" component={SettingsScreen} />
    </Tab.Navigator>
  );
}

function App() {
  return (
    <NavigationContainer theme={MyTheme}>
      <Stack.Navigator initialRouteName="Login">
        <Stack.Screen 
          name="Login" 
          component={LoginScreen} 
          options={{ headerShown: false }} 
        />
        <Stack.Screen 
          name="Signup" 
          component={SignupScreen} 
          options={{ headerShown: false }} 
        />
        <Stack.Screen 
          name="HomeTabs" 
          component={HomeTabs} 
          options={{ headerShown: false }} 
        />
        <Stack.Screen 
          name="ChangePassword"  
          component={ChangePasswordScreen} 
          options={{
            headerTitle: () => <Text style={{ fontSize: 24 }}></Text>,  // Ensure title wrapped in Text
            headerStyle: { backgroundColor: '#364649' },
            headerTintColor: '#E0D8CC',
          }}
        />
        <Stack.Screen
          name="AccountDetails"
          component={AccountDetailsScreen}
          options={{
            headerTitle: () => <Text style={{ fontSize: 24 }}></Text>,
            headerStyle: { backgroundColor: '#364649' },
            headerTintColor: '#E0D8CC',
          }}
        />
        <Stack.Screen
          name="UpdateContactDetails"
          component={UpdateContactDetailsScreen}
          options={{
            headerTitle: () => <Text style={{ fontSize: 24 }}></Text>,
            headerStyle: { backgroundColor: '#364649' },
            headerTintColor: '#E0D8CC',
          }}
        />
        <Stack.Screen
          name="RecoverPassword"
          component={RecoverPasswordScreen}
          options={{
            headerTitle: () => <Text style={{ fontSize: 24 }}>Recover Password</Text>,
            headerStyle: { backgroundColor: '#364649' },
            headerTintColor: '#E0D8CC',
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;
