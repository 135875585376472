import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, ActivityIndicator, StyleSheet, Image, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import { API_URL } from '../services/api';

export default function SignupScreen() {
  const { colors } = useTheme();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState(null); // IP state
  const [device, setDevice] = useState(''); // Device state
  const navigation = useNavigation();
  const fileInputRef = useRef(null);

  // Function to get the device type
  const getDeviceType = () => {
    if (Platform.OS === 'ios') {
      return 'iOS';
    } else if (Platform.OS === 'android') {
      return 'Android';
    } else if (Platform.OS === 'web') {
      return 'PC';
    } else {
      return 'Unknown';
    }
  };

  // Fetch public IP on component mount
  useEffect(() => {
    const fetchPublicIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    setDevice(getDeviceType()); // Set the device type
    fetchPublicIP(); // Fetch the public IP
  }, []);

  const handleSignup = async () => {
    if (!fullName.trim() || !email.trim() || !password.trim()) {
      Alert.alert('Error', 'All fields are required');
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('username', fullName); // Map to username
      formData.append('email', email);
      formData.append('password', password);
      formData.append('device', device); // Append device type
      formData.append('ip', ipAddress); // Append public IP

      if (avatar && avatar.uri) {
        if (Platform.OS === 'web') {
          formData.append('avatar', avatar.file);
        } else {
          formData.append('avatar', {
            uri: avatar.uri,
            type: 'image/jpeg',
            name: avatar.uri.split('/').pop(),
          });
        }
      } else {
        formData.append('avatar', 'avatar.png');
      }

      const response = await axios.post(`${API_URL}/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        Alert.alert('Success', 'Account created successfully. Please log in.');
        navigation.navigate('Login');
      } else {
        Alert.alert('Error', response.data.error || 'Unknown error');
      }
    } catch (error) {
      console.error('Signup error:', error.response || error);
      Alert.alert('Signup error', error.response?.data?.error || 'An error occurred during sign up.');
    } finally {
      setLoading(false);
    }
  };

  const handleChooseAvatar = async () => {
    if (Platform.OS === 'web') {
      fileInputRef.current.click();
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.canceled) {
        setAvatar(result.assets[0]);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const localUri = URL.createObjectURL(file);
      setAvatar({ uri: localUri, file: file, name: file.name, type: file.type });
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Create Account</Text>
  
      <TextInput
        style={styles.input}
        placeholder="Username"
        placeholderTextColor="#A5A5A5"
        value={fullName}
        onChangeText={setFullName}
        autoCapitalize="words"
      />
      <TextInput
        style={styles.input}
        placeholder="Email"
        placeholderTextColor="#A5A5A5"
        value={email}
        onChangeText={setEmail}
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <TextInput
        style={styles.input}
        placeholder="Password"
        placeholderTextColor="#A5A5A5"
        secureTextEntry={true}
        value={password}
        onChangeText={setPassword}
        autoCapitalize="none"
      />
  
      <TouchableOpacity onPress={handleChooseAvatar} style={styles.avatarButton}>
        <Text style={styles.avatarButtonText}>Choose Avatar</Text>
      </TouchableOpacity>
  
      {avatar && <Image source={{ uri: avatar.uri }} style={styles.avatarImage} />}
  
      {loading ? (
        <ActivityIndicator size="large" color="#AA895F" /> 
      ) : (
        <TouchableOpacity style={styles.signupButton} onPress={handleSignup}>
          <Text style={styles.signupButtonText}>Sign up</Text>
        </TouchableOpacity>
      )}
  
      {/* Conditionally render <input> for web only */}
      {Platform.OS === 'web' && (
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
      )}

      <TouchableOpacity onPress={() => navigation.navigate('Login')}>
        <Text style={styles.loginText}>
          Already have an account? <Text style={styles.loginLink}>Log in</Text>
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#364649', // Fundal schimbat la culoarea din paletă
  },
  title: {
    color: '#E0D8CC', // Schimbat la culoare deschisă din paletă
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    width: '80%',
    height: 50,
    backgroundColor: '#708F96', // Fundal schimbat pentru input
    borderRadius: 10,
    color: '#E0D8CC', // Text de culoare deschisă
    paddingLeft: 15,
    marginBottom: 20,
  },
  avatarButton: {
    width: '80%',
    height: 50,
    backgroundColor: '#AA895F', // Culoare buton avatar
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 20,
  },
  avatarButtonText: {
    color: '#364649', // Text contrastant pe buton
    fontSize: 16,
  },
  avatarImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 20,
  },
  signupButton: {
    width: '80%',
    height: 50,
    backgroundColor: '#AA895F', // Culoare pentru buton de signup
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 20,
  },
  signupButtonText: {
    color: '#364649', // Text contrastant pe buton
    fontSize: 18,
  },
  loginText: {
    color: '#E0D8CC', // Culoare pentru textul de login
    marginTop: 20,
  },
  loginLink: {
    color: '#AA895F', // Culoare pentru link-ul de login
  },
});
