import React, { useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, StyleSheet, Platform, Keyboard, TouchableWithoutFeedback  } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { API_URL } from '../services/api';
import { useNavigation } from '@react-navigation/native';

export default function ChangePasswordScreen() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigation = useNavigation();


  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword) {
      if (Platform.OS === 'web') {
        window.alert('Error: Both fields are required.');
      } else {
        Alert.alert('Error', 'Both fields are required.');
      }
      return;
    }
  
    try {
      const userId = await AsyncStorage.getItem('user_id'); // Fetch user ID from AsyncStorage
      const token = await AsyncStorage.getItem('token'); // Fetch the authentication token
  
      if (!token) {
        if (Platform.OS === 'web') {
          window.alert('Error: You are not authenticated. Please log in again.');
        } else {
          Alert.alert('Error', 'You are not authenticated. Please log in again.');
        }
        return;
      }
  
      const response = await axios.put(
        `${API_URL}/user/${userId}/change-password`,
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token in Authorization header
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.success) {
        if (Platform.OS === 'web') {
          window.alert('Success: Password changed successfully!');
        } else {
          Alert.alert('Success', 'Password changed successfully!');
        }
        navigation.goBack(); // Navigate back after password change
      } else {
        const errorMessage = response.data.message || 'Password change failed.';
        if (Platform.OS === 'web') {
          window.alert(`Error: ${errorMessage}`);
        } else {
          Alert.alert('Error', errorMessage);
        }
      }
    } catch (error) {
      console.error('Error changing password:', error.response?.data || error.message);
      const errorMsg = 'There was a problem changing your password.';
      if (Platform.OS === 'web') {
        window.alert(`Error: ${errorMsg}`);
      } else {
        Alert.alert('Error', errorMsg);
      }
    }
  };
  
  const Container = Platform.OS === 'web' ? View : TouchableWithoutFeedback;
  return (
    <Container onPress={Platform.OS === 'web' ? null : Keyboard.dismiss}>
    <View style={styles.container}>
      <Text style={styles.title}>Change Password</Text>

      <TextInput
        style={styles.input}
        placeholder="Current Password"
        placeholderTextColor="#A5A5A5"
        secureTextEntry={true}
        value={currentPassword}
        onChangeText={setCurrentPassword}
        textContentType="password"
        autoCompleteType="password"
        autoCapitalize="none"
      />

      <TextInput
        style={styles.input}
        placeholder="New Password"
        placeholderTextColor="#A5A5A5"
        secureTextEntry={true}
        value={newPassword}
        onChangeText={setNewPassword}
        textContentType="newPassword"
        autoCompleteType="password"
        autoCapitalize="none"
      />

      <TouchableOpacity style={styles.changeButton} onPress={handleChangePassword}>
        <Text style={styles.changeButtonText}>Change Password</Text>
      </TouchableOpacity>
    </View>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#364649', // Updated background color
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  title: {
    color: '#E0D8CC', // Updated text color
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    height: 50,
    backgroundColor: '#708F96', // Updated input background color
    borderRadius: 10,
    color: '#E0D8CC', // Updated input text color
    paddingLeft: 15,
    marginBottom: 20,
    fontSize: 16,
  },
  changeButton: {
    width: '100%',
    height: 50,
    backgroundColor: '#AA895F', // Updated button color
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  changeButtonText: {
    color: '#364649', // Updated button text color
    fontSize: 18,
  },
});
